import * as React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { Pagination } from '../../../ui/pagination/Pagination';
import { NewsListWrapper, NewsCard } from '../../../ui/cards/News';
import { Heading } from '../../../ui/text/Heading';
import { OFFICE_NEWS_QUERY } from '../../../../../client/__graphql__/queries/office/news/news';

export const NewsList: React.FC<{ urltag: string }> = ({ urltag }) => {
  const [page, setPage] = React.useState(0);

  const { data } = useQuery(OFFICE_NEWS_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        urltag: urltag,
        page: page
      }
    }
  });

  return (
    <NewsWrapper>
      <Heading tag="h2" center>
        Andre nyheter
      </Heading>
      <NewsListWrapper>
        {data?.getAllNews?.list.map((item: any, index: number) => {
          return (
            <NewsCard
              key={`news_${index}`}
              title={item.title}
              ingress={item.ingress}
              image={item.image}
              slug={item.slug}
              currentPage={page}
            />
          );
        })}
      </NewsListWrapper>
      {data?.getAllNews?.list?.length ? (
        <Pagination
          center={true}
          currentPage={page}
          maxPages={data?.getAllNews?.total}
          total={data?.getAllNews?.total}
          itemsPerPage={24}
          pageOnChange={({ selected }) => {
            setPage(selected);
            if (typeof window !== 'undefined') {
              window.scroll(0, 0);
            }
          }}
        />
      ) : null}
    </NewsWrapper>
  );
};

const NewsWrapper = styled.div``;

import * as React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { RouteComponentProps } from 'react-router';
import { Container } from '../../../layout/Container';
import { Heading } from '../../../ui/text/Heading';
import { Section } from '../../../layout/Section';
import { BackgroundLoad } from '../../../layout/BackgroundLoad';

import { OFFICE_SINGLE_NEWS_QUERY } from '../../../../../client/__graphql__/queries/office/news/singleNews';

import { useFadeTransition } from '../../../../utils/hooks/useFadeTransition';
import { FormValuation } from './FormValuation';
import { Seo } from '../../../../utils/seo/Index';
import { NewsList } from './NewsList';
import { useProgressiveImage } from '../../../ui/slideshow/hooks/useProgressiveImage';

interface IRoute {
  urltag: string;
  slug: string;
}

const SingleNews: React.FC<RouteComponentProps<IRoute>> = ({
  match,
  history,
  location
}) => {
  const { data, loading } = useQuery(OFFICE_SINGLE_NEWS_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        urltag: match?.params?.urltag,
        slug: match?.params?.slug
      }
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors?.map((item: any, index: number) => {
        if (item?.extensions?.code === '404') {
          history.replace(`/finner-ikke-side`);
        }
      });
    }
  });

  const { fadeIn } = useFadeTransition({
    loaded: !loading
  });

  return (
    <>
      <Seo
        title={data?.getSingleNews?.title}
        description={data?.getSingleNews?.ingress}
        image={`https://meglervalget.no/gfx/${data?.getSingleNews?.image}`}
        url={location.pathname}
      />
      <Container
        style={{ paddingTop: '100px', minHeight: '1000px', ...fadeIn }}
      >
        <Section>
          <NewsImage
            url={`https://meglervalget.no/gfx/${data?.getSingleNews?.image}`}
          />
          <Heading tag="h1">{data?.getSingleNews?.title}</Heading>
          <NewsIngress>{data?.getSingleNews?.ingress}</NewsIngress>
          <NewsBody
            style={{ marginBottom: '60px', display: 'block' }}
            dangerouslySetInnerHTML={{ __html: data?.getSingleNews?.body }}
          />
          <FormValuation urltag={match?.params?.urltag} />
          <Section className="center">
            <NewsList urltag={match?.params?.urltag} />
          </Section>
        </Section>
      </Container>
      <BackgroundLoad
        opacity={0.3}
        position="center"
        url="https://cdn.reeltime.no/pm_assets/img/bakgrunner/hjort_bakside2.jpg"
      />
    </>
  );
};

export default SingleNews;

const NewsImage: React.FC<{ url: string }> = ({ url }) => {
  const { loaded } = useProgressiveImage({
    url: url
  });
  return <NewsImg src={url} loaded={loaded} />;
};

const NewsIngress = styled.p`
  font-family: 'DomaineText-Regular', 'Times New Roman', serif;
  font-size: 1.5em;
  margin-top: 30px;
  margin-bottom: 20px;
`;

const NewsImg = styled.img<{ loaded: boolean }>`
  transition: opacity 0.1s ease-in-out;
  width: 100%;
  margin-bottom: 30px;
  opacity: ${(props) => (props.loaded ? 1 : 0)};
`;

const NewsBody = styled.div`
  figure {
    margin: 0;
  }
  img {
    width: 100%;
  }
`;

import { gql } from '@apollo/client';

export const OFFICE_SINGLE_NEWS_QUERY = gql`
  query GetSingleNews($input: GetSingleNewsInput!) {
    getSingleNews(input: $input) {
      title
      image
      imageDescriptionTop
      imageDescriptionBottom
      ingress
      body
      slug
    }
  }
`;
